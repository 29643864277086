



























































































































































































































































import { required, minLength, numeric, maxLength } from 'vuelidate/lib/validators';
import { phoneFormat, phoneValidation } from '@shared/services/validators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Routes } from '@rs-911/router/routes';
Component.registerHooks(['validations']);

@Component
export default class UpdateSecondPartner extends Vue {
  $v: any;
  routes = Routes;
  showOverlay = false;
  loginFailMessage = '';
  registrationSuccess = false;
  options = [
    { value: '', text: '' },
    { value: 'Mr.', text: 'Mr.' },
    { value: 'Mrs.', text: 'Mrs.' },
    { value: 'Ms.', text: 'Ms.' },
  ]
  form = {
    Name: null,
    Address1: null,
    Address2: null,
    City: null,
    State: null,
    Zip: null,
    ContactFirstName: null,
    ContactLastName: null,
    ContactPrefix: null,
    ContactTitle: null,
    ContactPhone: '',
    ContactPhoneExt: null
  }

  created() {
    this.$store.dispatch('auth/getSecondPartner').then(res => {
      this.form = {
        Name: res.name,
        Address1: res.address1,
        Address2: res.address2,
        City: res.city,
        State: res.state,
        Zip: res.zip,
        ContactFirstName: res.contactFirstName,
        ContactLastName: res.contactLastName,
        ContactPrefix: res.contactPrefix,
        ContactTitle: res.contactTitle,
        ContactPhone: res.contactPhone,
        ContactPhoneExt: res.contactPhoneExt
      };
    });
  }

  @Watch('form.ContactPhone')
  formContactPhone(newVal: string) {
    this.form.ContactPhone = phoneFormat(newVal);
  }

  validations() {
    return {
      form: {
        Name: {
          required
        },
        Address1: {
          required
        },
        Address2: {},
        City: {
          required
        },
        State: {
          required
        },
        Zip: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        ContactFirstName: {
          required
        },
        ContactLastName: {
          required
        },
        ContactPrefix: {},
        ContactTitle: {
          required
        },
        ContactPhone: {
          required,
          phoneValidation
        },
        ContactPhoneExt: {}
      }
    };
  }


  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      Name: null,
      Address1: null,
      Address2: null,
      City: null,
      State: null,
      Zip: null,
      ContactFirstName: null,
      ContactLastName: null,
      ContactPrefix: null,
      ContactTitle: null,
      ContactPhone: '',
      ContactPhoneExt: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    this.registrationSuccess = false;
    if (this.$v.form.$anyError) {
      return;
    }
    this.showOverlay = true;
    this.loginFailMessage = '';
    const sendForm = {...this.form};
    sendForm.ContactPhone = sendForm.ContactPhone.replace(/\D/g, '');

    this.$store.dispatch('auth/updateSecondPartner', sendForm)
      .then(() => {
        this.registrationSuccess = true;
        this.$store.dispatch('notify', {title: 'Info Updated', body: 'You successfully updated your info.', variant: 'success'});
        this.$router.push(this.routes.portal);
        this.resetForm();
      })
      .catch(err => {
        this.loginFailMessage = err.data.message;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to update your info.', variant: 'danger'});
      })
      .finally(() => this.showOverlay = false);
  }
  
}
